@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  // slate-800
  background-color: #1e293b;
}

@layer utilities {
  .project-backdrop {
    background-image: linear-gradient(
      45deg,
      hsl(38deg 92% 50%) 0%,
      hsl(38deg 92% 54%) 11%,
      hsl(39deg 92% 57%) 22%,
      hsl(40deg 92% 60%) 33%,
      hsl(41deg 92% 63%) 44%,
      hsl(42deg 92% 66%) 56%,
      hsl(43deg 92% 68%) 67%,
      hsl(45deg 93% 71%) 78%,
      hsl(46deg 95% 74%) 89%,
      hsl(48deg 97% 77%) 100%
    );
    aspect-ratio: 16 / 9;
  }
}
